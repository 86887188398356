<template>
<div>
    
    <p>link verificar email</p>

</div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';

import {
    mapState
} from "vuex";

export default {
    name: "verifyEmail",
    components: {},
    data() {
        return {
            id: '',
            hash: '',
            signature: '',
            expiry: '',
            successMessage: '',
        };
    },
    created() {
        console.log("Component verify.");
    },
    mounted() {
        this.emailVerify();
    },
    methods: {
        emailVerify(){
            this.id = this.$route.query.id
            this.hash = this.$route.query.hash
            this.signature = this.$route.query.signature
            this.expiry = this.$route.query.expiry
            const url = this.appDomainApi1 + `email/verify/${this.id}?hash=${this.hash}&expiry=${this.expiry}&signature=${this.signature}`;
            axios
                .get(url)
                    .then(response => {
                        this.successMessage = response.message
                    }).catch(error => {
                        this.errorMessage = error
                    })
        }
    },
    computed: {
        ...mapState([
            'student',
            'block'
        ]),
    },
};
</script>

<style scoped>
.imgav {
    display: flex;
    align-content: center;
    justify-content: center;
}

.cAvatar {
    position: relative;
    width: 150px;
    height: 150px;
    background-color: white;
    border-radius: 50%;
    overflow: hidden;
}

.cImage {
    position: absolute;
    width: 150px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
</style>
